* {
  font-family: "Jost", sans-serif;
}
/* Footer */
.footer-sec {
  background-color: #0d354f;
}
.footer-logo {
  width: 185px;
}
.logo-para {
  font-size: 16px;
  font-weight: 400;
  color: #d9d9d9;
}
.logo-para span {
  font-size: 16px;
  font-weight: 700;
  color: #d9d9d9;
}
.foot-links h2 {
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  padding-bottom: 16px;
}
.foot-links li {
  list-style-type: none;
  padding: 4px 0px;
}
.foot-links li a {
  text-decoration: none;
  color: #d3cece !important;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.foot-links p {
  font-size: 16px;
  font-weight: 500;
  color: #d9d9d9;
}
.navCall a {
  text-decoration: none;
  color: #d3cece;
  font-size: 16px;
  font-weight: 500;
}
.social-media a {
  text-decoration: none;
  color: #fff !important;
  font-size: 20px;
  cursor: pointer;
}

/* Home */
.reviewBanner {
  background-image: url("../public/assets/testimonal.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.review-box p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  text-align: center;
}
.review-box img {
  width: 80px;
}
.review-box h6 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.css-15befwp-MuiRating-root {
  color: #d11243 !important;
}
.review-white {
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #d9d9d9 !important;
}
.qualityBanner {
  width: 80%;
}
.quality-bannerimg {
  background-image: url("../public/assets/quality\ meat\ banner.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.qualityBanner p {
  font-size: 16px;
  font-weight: 400;
  color: #ececec;
}
.qualityBanner p span {
  font-size: 35px;
  font-weight: 400;
  color: #fff;
  font-family: "Kiwi Maru", serif !important;
}
.briyani-img {
  width: 508px !important;
}
.briyani h2 {
  font-size: 30px;
  font-weight: 700;
}
.briyani p {
  font-size: 16px;
  font-weight: 400;
}
.briyani h3 {
  font-size: 20px;
  font-weight: 700;
}
.briyani h6 {
  font-size: 16px;
  font-weight: 500;
}
.briyani ul li {
  font-size: 16px;
  font-weight: 400;
}
.briyani ul li::marker {
  font-size: 10px;
}
.value-bg {
  background-image: url("../public/assets/neutriBg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.chicken-img {
  width: 355px;
}
.chick {
  width: 80px !important;
  height: 60px !important;
}
.chick2 {
  width: 80px !important;
  height: 60px !important;
}
.nuti-box h2 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}
.nuti-box h2 span {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.nuti-box h6 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding: 10px 0px 15px 0px;
}
.nuti-box p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

/* Card */
.item-img {
  transition: transform 0.3s ease;
}
.item-img:hover {
  transform: scale(1.1);
}
.item-head {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.item-heart {
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 10px 50px;
}
.heart-icon {
  color: #fff;
  font-size: 1rem;
  margin-left: 5px;
}
.ratingStar {
  background-color: #fff;
  width: 50px;
  border-radius: 5px;
}
.item-foot {
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: #ffffff33;
  backdrop-filter: blur(25px);
  border-bottom-left-radius: 10px;
  width: 100%;
  overflow: hidden;
  border-bottom-right-radius: 60px;
}
:where(.css-1fumvat).ant-card .ant-card-body {
  padding: 0px !important;
}
@media (max-width: 767px) {
  .item-foot {
    bottom: 0;
    width: 100%;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px !important;
    position: static !important;
  }
  .item-card {
    margin-bottom: 0.5rem !important;
    padding: 0px 5px !important;
  }
  .item-img {
    border-radius: 0px !important;
  }
  :where(.css-dev-only-do-not-override-kghr11).ant-card-bordered {
    border-radius: 0px !important;
  }
  .increDec {
    margin-top: 5px !important;
  }
  .mobile-card {
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .review1 {
    height: auto !important;
  }
  .sm-chickenbanner {
    display: none;
  }
}

@media (max-width: 1024px) {
  .sm-chickenbanner {
    display: none;
  }
}
.card-price {
  font-weight: 800 !important;
  color: #fff !important;
  margin-bottom: 0px !important;
  font-size: 20px !important;
}
.cartBtn {
  background-color: #0d354f !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.card-item-head {
  font: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px !important;
}
.increDec {
  overflow-wrap: break-word;
  white-space: normal;
  text-decoration: none;
  line-height: 1.2;
  letter-spacing: 0px;
  height: 22px;
  min-height: 22px;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: rgb(0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: rgb(192, 192, 192);
  border-radius: 5px;
  border-style: solid;
  display: flex;
  align-items: center;
  padding: 5px;
}
.minus {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  opacity: 0.7;
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
}
.plus {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  opacity: 0.7;
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
}
.inputQuantity {
  border-width: 0px;
  padding: 0px;
  width: 40px;
  text-align: center;
  outline: none;
  margin: 0px;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: rgb(51, 51, 51);
  background-color: transparent;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-card .ant-card-body {
  padding: 0px !important;
}
.card-dec1 {
  width: 90%;
  justify-content: space-between;
}
.lg-amount {
  font-weight: 600;
}
@media (max-width: 567px) {
  .card-dec1 {
    /* justify-content:start; */
    width: 100%;
  }
  .amount {
    width: 50%;
  }
}
.referal-codebanner h2 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}
.referal-codebanner h2 span {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.refer-banner {
  background-color: #0d354f;
}
.refer-btn {
  background-color: #fff !important;
  color: #0d354f !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  width: 160px !important;
  height: 40px !important;
}
.allitems-nav h2 {
  font-size: 30px;
  font-weight: 800;
}
.allitems-nav span {
  font-size: 14px;
  font-weight: 400;
}
.nav-all p {
  font-size: 16px;
  font-weight: 400;
}
.nav-all a {
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000 !important;
}
.nav-all span {
  font-size: 16px;
  font-weight: 600;
}
.allitems {
  padding-top: calc(var(--bs-gutter-x) * 0.5) !important;
}
.ant-dropdown-trigger {
  background-color: #0d354f !important;
  color: #fff !important;
  border-radius: 5px !important;
  padding: 6px 25px !important;
  cursor: pointer !important;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-space-gap-col-small {
  column-gap: 40px !important;
  font-weight: 700 !important;
}
.small-imgs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.small-imgs img {
  margin: 20px 0px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: transparent !important;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  background-color: #d9d9d9 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-select-single
  .ant-select-selector {
  border-radius: 0px !important;
  border-color: #0d354f !important;
}
@media (max-width: 768px) {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
}
.title2 {
  font-size: 30px;
  font-weight: 700;
}
.title2 span {
  font-size: 30px;
  font-weight: 700;
  color: #c30c11;
}
.para {
  font-size: 16px;
  font-weight: 500;
}
.nutriMutton {
  font-size: 30px !important;
  font-weight: 700 !important;
  color: #c30c11 !important;
}
.nut-con span {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.nut-con p {
  font-size: 20px !important;
  font-weight: 400 !important;
}

@media (max-width: 1024px) {
  .nut-con p {
    font-size: 11px !important;
    font-weight: 400 !important;
  }
}
.mutton-poster {
  height: 100% !important;
}
.banner {
  background-image: url("../public/assets/banner-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.banner-con h2 {
  font-size: 76px;
  font-weight: 800;
  color: #fff;
}
.banner-con p {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0px;
}
.b-Para {
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 1024px) {
  .b-Para {
    font-size: 10px;
    font-weight: 700;
  }
}
.log-box {
  position: absolute;
  right: 0px;
  top: 80px;
  background-color: #fff;
  width: 40%;
  color: #000;
  border-radius: 10px;
  box-shadow: 1px 1px 11px 1px rgba(0,0,0,0.75);
  border: 2px solid #0d354f;
}

@media (max-width: 768px) {
  .log-box {
    position: absolute;
    right: 0px;
    top: 80px;
    background-color: #fff;
    width: 100%;
    color: #000;
    border-radius: 10px;
  }
  .login {
    height: 95vh;
    overflow-y: auto;
  }
  .login p {
    font-size: 12px !important;
  }
  .tablecart table {
    font-size: 12px !important;
  }
}

.table {
  padding: 10px 30px;
}
.table table tr td {
  border: none !important;
}
.table table tr td button {
  background-color: #0d354f !important;
  font-size: 10px;
}
.login {
  padding: 10px 15px;
}
.login label {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px;
}
.login input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #1f1f1f;
}
.login button {
  background-color: #0d354f !important;
  padding: 5px 40px !important;
}
.login p {
  font-size: 16px;
  font-weight: 700;
  color: #171717;
}
.login p a {
  font-size: 14px;
  font-weight: 600;
  color: #171717;
  cursor: pointer;
}
.tablecart table {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.strike {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #7f7f7f !important;
  text-decoration: line-through;
}
.miss {
  font-size: 16px;
  font-weight: 700;
  padding-top: 16px;
}
.miss-cart {
  width: 100%;
  height: 144px !important;
  border-radius: 5px;
}
.extra h5 {
  font-size: 16px;
  font-weight: 700;
}
.extra h2 {
  font-size: 20px;
  font-weight: 700;
}
#time-activebtn {
  background-color: #0d354f !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
}
#time-btn {
  background-color: transparent !important;
  color: #bcbcbc !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  border: 1px solid #0d354f;
  box-shadow: none !important;
}
.ant-btnsubmit {
  height: 40px !important;
}
.h5-sum {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 0px;
}
.table-sum td {
  font-size: 14px;
  font-weight: 600;
  color: #434343;
  padding: 6px 0px;
  border-bottom: 1px solid #000;
}
.table-sum {
  padding: 10px 50px;
}
.summary-box{
  overflow-y: auto;
  height: 80vh;
}
.address {
  padding: 10px 40px;
}
.address h3 {
  font-size: 16px;
  font-weight: 700;
}
.address label {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0px;
}
.address input {
  width: 100%;
  border: 1px solid #171717;
  height: 40px;
}
.loc-btn {
  background-color: #454545 !important;
  padding: 10px 30px !important;
}
.loc-btn2 {
  background-color: transparent !important;
  padding: 10px 30px !important;
  color: #171717 !important;
  border: 1px solid #171717 !important;
}

@media (max-width: 768px) {
  .mobie-del{
    margin-left: 12px;
  }
  .loc-btn {
    background-color: #454545 !important;
    padding: 1px 20px !important;
  }
  .loc-btn2 {
    background-color: transparent !important;
    padding: 1px 20px !important;
    color: #171717 !important;
    border: 1px solid #171717 !important;
  }
}
.savebtn {
  background-color: #0d354f !important;
}
.order-box {
  border: 1px solid #171717;
  border-radius: 10px;
  padding: 12px;
}
.order-box p {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 700;
}
.razor-div {
  background-color: #0d354f;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 10px;
}
.pay {
  width: 80%;
  border: 1px solid #171717;
  border-radius: 10px;
}
.pay h4 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
}
.pay p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}
.see-btn {
  background-color: #0d354f !important;
  padding: 5px 40px !important;
}
@media (max-width: 768px) {
  .pay h4 {
    font-size: 10px;
  }
  .pay p {
    font-size: 10px;
  }
}
.head-Icon {
  color: #fff !important;
}
.play-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #0d354f;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playicon {
  color: #fff;
  font-size: 30px;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-picker-outlined {
  width: 95%;
}
#sect-chickActive {
  padding: 5px 25px !important;
  background-color: transparent !important;
  border: 1px solid #d11243 !important;
  color: #000 !important;
}
#sect-chick {
  padding: 5px 25px !important;
  background-color: transparent !important;
  border: 1px solid #171717 !important;
  color: #000 !important;
}
.sect-input {
  width: 75px !important;
  height: 30px !important;
  padding: 2px;
}
input {
  padding: 5px;
}
.css-104zre5-MuiInputBase-root .MuiInputBase-input {
  width: 250px !important;
}

.field-expri {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fresh{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width:1024px){
  .field-expri {
     height: 80px;
  }
  .fresh{
    justify-content:space-around
  }
}
.viewadd-cart-mobile{
  background-color: #0d354f !important;
  color: #fff !important;
  width: 100% !important;
}

@media (min-width:768px) and (max-width:998px) {
  .md-size h5{
    font-size: 12px !important;
  }
  .md-size p{
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .sm-banner {
    display: none;
  }
  .fresh{
    flex-direction: column;
  }
  .small-imgs{
    flex-direction: row;
    gap:15px;
  }
  .fresh-img{
    height: 50px !important;
    width: 50px !important;
  }
  .field-expri {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .css-dh5cuc {
    width: 370px !important;
  }
  .foot-links h2 {
    padding-bottom: 10px;
  }
}
.banner-carsoul {
  position: absolute;
  right: 0;
  top: 38%;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: transparent !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 0px 5px !important;
}
.rating-box h4 {
  font-size: 16px;
  font-weight: 600;
}
.rating-box textarea {
  width: 100%;
}
.rating-box button {
  background-color: #0d354f !important;
  padding: 5px 30px !important;
}
.inputQuantity {
  width: 58px !important;
}
.discount {
  font-size: 12px;
  font-weight: 700;
  color: #0d354f;
}
.css-i6s8oy {
  justify-content: space-between !important;
}
:where(.css-1fumvat).ant-card .ant-card-body {
  padding: 0px !important;
  display: none !important;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #0d354f !important;
  background-color: #0d354f !important;
}
.ant-card .ant-card-bordered .css-dev-only-do-not-override-1fumvat {
  border-radius: 0px !important;
}
@media (max-width: 768px) {
  .cartBtn {
    background-color: #0d354f !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    padding: 2px 8px !important;
  }
}
.css-1q2h7u5.Mui-selected {
  color: #0d354f !important;
}

.truncated-text {
  display: inline-block;
  max-width: 140px; /* Adjust the width based on font size and desired characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.og-price {
  font-weight: 600;
  text-decoration: line-through;
}
.lg-hide {
  display: none;
}
@media (max-width: 768px) {
  .lg-hide {
    display: block;
  }
}
:where(.css-dev-only-do-not-override-1fumvat).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #0d354f !important;
  border-color: #0d354f !important;
}
.discount-strike {
  text-decoration: line-through;
}
.discount-amount2 {
  font-weight: 700;
  font-size: 25px;
  margin-right: 10px;
}

.discount {
  border: 1px solid #a6e47f;
  background-color: #a6e47f;
  border-radius: 5px;
  padding: 2px;
}
.piece {
  position: absolute;
  top: 25px;
  left: 10px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}
.login-bg {
  background-image: url("../public/assets/neutriBg.webp");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.but {
  background-color: #0d354f !important;
  color: #fff !important;
}
.referBox{
  background-color: #0D354F;
}
.referbox-content h6{
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.referbox-content p{
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin: 10px 0px;
}
.referbox-content span{
  font-size: 24px;
  font-weight: 700;
}
.referbox-content button{
  background-color: #fff !important;
  color: #0D354F !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.coin{
  position: absolute;
  top: 30px;
  left: 77px;
}
@media(max-width:768px){
  .referbox-content h6{
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }
  .referbox-content p{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin: 10px 0px;
  }
  .referbox-content span{
    font-size: 20px;
    font-weight: 700;
  }
  .referbox-content button{
    background-color: #fff !important;
    color: #0D354F !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .banner{
    /* background-position: left; */
    height: auto;

  }
  .banner-carsoul {
    /* top: 60%; */
    position: static;
    padding: 15px 0px;

  }
  .kMZWeq .slick-slide:not(.slick-center) img{
    border: 2px solid #fff !important;
  }
}
:where(.css-1fumvat).ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  border-color: #0D354F !important;
  background-color: #0D354F !important;
}

@media screen and (max-width: 768px) {
  /* Apply margin-top of -10px for screens up to 768px wide (mobile devices) */
  .nuti-box h2 {
    margin-top: -29px;
  }  
}

/* rohit */
/* .card-div {
  margin: 0 auto;
  padding-top: 100px; 
} */

.cont-div {
  padding-top: 150px;
}

.blog-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.blog-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;

}

.blog-image {
  height: 70vh !important;
  width: 100%;
  object-fit: cover; 
  object-position: center;
  margin-bottom: 10px;
  border-radius: 10px;
}


.blog-content h3 {
  margin-top: 20px;
  font-weight: bold;
}

.blog-content p {
  line-height: 1.8;
  font-size: 1.1rem;
}

.first-indent {
  text-indent: 30px;
}

.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}
.custom-spin .ant-spin-dot i {
  background-color: #0D354F !important;
}


/* .row {
  justify-content: center;
}

.col {
  display: flex;
  justify-content: center;
} */
.bl{
  color: #000;
}